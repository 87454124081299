// PDFPreviewTab.js
import React from "react";
import { PDFViewer } from "@react-pdf/renderer";
import { Box } from "@mui/material";
// import { Download as DownloadIcon } from "@mui/icons-material";

const PDFPreviewTab = ({ documentComponent, fileName }) => (
  <Box>
    {/* <PDFDownloadLink document={documentComponent} fileName={fileName}>
      {({ blob, url, loading, error }) => (
        <Button
          variant="contained"
          color="primary"
          startIcon={<DownloadIcon />}
        >
          {loading ? "Loading..." : "Download"}
        </Button>
      )}
    </PDFDownloadLink> */}
    <Box mt={2}>
      <PDFViewer width="100%" height="600">
        {documentComponent}
      </PDFViewer>
    </Box>
  </Box>
);

export default PDFPreviewTab;
