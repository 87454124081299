import { Font } from "@react-pdf/renderer";

// Importing Roboto font variants using the 'public' alias
// import RobotoThin from "./fonts/Roboto/Roboto-Thin.ttf";
// import RobotoLight from "./fonts/Roboto/Roboto-Light.ttf";
import RobotoMedium from "./fonts/Roboto/Roboto-Medium.ttf";
import RobotoBold from "./fonts/Roboto/Roboto-Bold.ttf";
import Buda from "./fonts/Buda/Buda.ttf";
import Libel from "./fonts/libel_suit/libel-suit-rg.otf";
// import RobotoBlack from "./fonts/Roboto/Roboto-Black.ttf";

// import RobotoItalic from "./fonts/Roboto/Roboto-Italic.ttf";
// import RobotoBoldItalic from "./fonts/Roboto/Roboto-BoldItalic.ttf";
Font.register({
  family: "Buda",
  fonts: [{ src: Buda, fontWeight: 400 }],
});
Font.register({
  family: "Libel",
  fonts: [{ src: Libel, fontWeight: 400 }],
});

Font.register({
  family: "Roboto",
  fonts: [
    // { src: RobotoThin, fontWeight: 100 },
    // { src: RobotoLight, fontWeight: 300 },
    { src: RobotoMedium },
    { src: RobotoMedium, fontWeight: 500 },
    { src: RobotoBold, fontWeight: 700 },
    // { src: RobotoBlack, fontWeight: 900 },
    // { src: RobotoItalic, fontStyle: "italic" },
    // { src: RobotoBoldItalic, fontWeight: "bold", fontStyle: "italic" },
  ],
});
