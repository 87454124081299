import React from "react";
// import Roboto from "../../../public/assets/fonts/Roboto/Roboto-Regular.ttf";
// import { PDFViewer } from "@react-pdf/renderer";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  // Font,
} from "@react-pdf/renderer";

// Register font
// Font.register({ family: "Roboto", src: Roboto });

const getStyles = (numProjects) =>
  StyleSheet.create({
    page: {
      fontFamily: "Roboto",
      flexDirection: "column",
      backgroundColor: "#d3d2d0",
      fontWeight: "500",
      display: "flex",
      height: "100%",
      // justifyContent: "flex-start",
      // alignItems: "center",
    },
    allProjects: {
      display: "flex",
      flexGrow: 1,
      margin: 0,
      height: "calc(90% - 55px)",
      flexDirection: "column",
      // border: "2pt solid #333",
    },
    projectContainer: {
      // flexGrow: 1,
      flex: 1,
      display: "flex",
      // height: `${100 / numProjects}%`,
      flexDirection: "column",
      // border: "2pt solid #333",
      borderBottom: "2pt solid #333",
      // padding: 8,
      backgroundColor: "#d3d2d0",
      margin: 0,
      padding: 0,
    },
    lextex: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "left",
      fontFamily: "Buda",
      fontSize: 14,
      fontWeight: "normal",
      color: "#a4a4a4",
      marginTop: 20,
      marginLeft: -12,
    },
    topbar: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      // justifyContent: "space-between",
      justifyContent: "space-between",
      alignItems: "center",
      margin: -8,
      padding: 0,
    },
    header: {
      fontFamily: "Roboto",
      fontSize: 40,
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    headerImage: {
      height: "55px",
      width: "55px",
    },
    context: {},
    contextText: {
      fontFamily: "Buda",
      // color: "#1c4586",
      color: "#435b7f",
      fontSize: 28,
      marginRight: 8,
    },

    titleContainer: {
      textAlign: "center",
      alignContent: "center",
      justifyContent: "flex-start",
      marginTop: 8,
      paddingTop: 8,
    },
    contentContainer: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "row",
      alignContent: "center",
    },
    detailsContainer: {
      flex: 7,
      margin: 8,
      padding: 8,
    },
    imageContainer: {
      flex: 5,
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      // marginTop: 8,
      margin: 8,
      // padding: 8,
      // marginTop: 8,

      // margin: 8,
      // padding: 8,
    },
    imageSubcontainer: {
      border: "3pt solid #343434",
      width: "100%",

      borderRadius: 8,
      // flex: 1,
      height: "80%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    screenshotContainer: {
      flexGrow: 1,
      flex: 3,
      justifyContent: "flex-end",
    },
    logoContainer: {
      // border: "3pt solid #aaa",
      // width: "100%",
      flex: 1,
      alignItems: "center",
      justifyContent: "flex-end",
      borderRadius: 8,
    },
    logoImage: {
      height: "100px",
      width: "auto",
      // borderRadius: 8,
    },
    image: {
      width: "100%",
      borderRadius: 4,
      // margin: 8,
      // border: "5pt solid #333",

      // height: 150,
    },
    title: {
      fontFamily: "Libel",
      textAlign: "center",
      color: "#435b7f",
      fontSize: 36,
      fontWeight: "400",
      // fontWeight: "900",
      // color: "#2E7D32",
      // marginBottom: 8,
    },
    budget: {
      fontFamily: "Roboto",
      fontSize: 18,
      fontWeight: "500",
      // color: "#55667f",
      color: "#5D4037",
      marginBottom: 8,
    },
    budgetText: {
      fontFamily: "Roboto",
      fontSize: 18,
      fontWeight: "500",
      color: "#435b7f",
      // color: "#55667f",
      marginBottom: 8,
      marginLeft: 8,
    },
    timeline: {
      fontFamily: "Roboto",
      fontSize: 18,
      fontWeight: "500",
      color: "#5D4037",
      marginBottom: 8,
    },
    timelineText: {
      fontFamily: "Roboto",
      fontSize: 18,
      fontWeight: "500",
      // color: "#55667f",
      color: "#435b7f",
      marginBottom: 8,
      marginLeft: 8,
    },
    techHeader: {
      fontFamily: "Roboto",
      fontSize: 18,
      fontWeight: "500",
      marginBottom: 5,
      color: "#5D4037",
    },
    techItem: {
      fontFamily: "Roboto",
      fontSize: 18,
      marginBottom: 4,
      height: "24px",
    },
    highlightHeader: {
      fontFamily: "Roboto",
      fontSize: 18,
      fontWeight: "500",
      marginTop: 10,
      marginBottom: 5,
      color: "#5D4037",
    },
    highlight: {
      fontFamily: "Roboto",
      fontSize: 18,
      // fontStyle: "italic",
      marginBottom: 4,
      color: "#616161",
    },
  });

const ProjectSection = ({ project, numProjects }) => {
  const styles = getStyles(numProjects); // Assuming 3 projects for now
  const titleUpper = project.title.toUpperCase();
  return (
    <View style={styles.projectContainer}>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>{titleUpper}</Text>
      </View>
      <View style={styles.contentContainer}>
        <View style={styles.imageContainer}>
          <View style={styles.imageSubcontainer}>
            <View style={styles.logoContainer}>
              <Image style={styles.logoImage} src={project.logo} />
            </View>
            <View style={styles.screenshotContainer}>
              <Image style={styles.image} src={project.screenshot} />
            </View>
          </View>
        </View>
        <View style={styles.detailsContainer}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Text style={styles.budget}>TOTAL COST:</Text>
            <Text style={styles.budgetText}>${project.cost}</Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Text style={styles.budget}>COMPUTE COST:</Text>
            <Text style={styles.budgetText}>${project.compute}</Text>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Text style={styles.timeline}>TIMELINE:</Text>
            <Text style={styles.timelineText}>{project.timeline}</Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Text style={styles.techHeader}>TECH:</Text>
            {project.technologies.map((tech, index) => (
              <Image key={index} src={tech} style={styles.techItem} /> // <Text key={index} style={styles.techItem}>
              // {tech}
              // </Text>
            ))}
          </View>
          <Text style={styles.highlightHeader}>HIGHLIGHTS:</Text>
          {project.highlights.map((highlight, index) => (
            <Text key={index} style={styles.highlight}>
              - {highlight}
            </Text>
          ))}
        </View>
      </View>
    </View>
  );
};

const MyDocument = ({ logo, projects }) => {
  const styles = getStyles(projects.length);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.topbar}>
          <View style={styles.header}>
            <Image style={styles.headerImage} src={logo} />
            <Text style={styles.lextex}>Lextex AI</Text>
          </View>
          <View style={styles.context}>
            <Text style={styles.contextText}>Project Snapshot</Text>
          </View>
        </View>
        <View style={styles.allProjects}>
          {projects.map((project, index) => (
            <ProjectSection
              key={index}
              project={project}
              numProjects={projects.length}
            />
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
