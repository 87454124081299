import React from "react";
// import ReactDOM from "react-dom";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CssBaseline, ThemeProvider } from "@mui/material";
// Define a custom theme
import customTheme from "./theme";
import "./fonts";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={customTheme}>
      <CssBaseline /> {/* Optional: Helps with consistent baseline styling */}
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
// ReactDOM.render(
//   <React.StrictMode>
//     <ThemeProvider theme={customTheme}>
//       <CssBaseline /> {/* Optional: Helps with consistent baseline styling */}
//       <App />
//     </ThemeProvider>
//   </React.StrictMode>,
//   document.getElementById("root")
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
