import { createTheme } from "@mui/material";

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#ff5722",
    },
    secondary: {
      main: "#2196f3",
    },
    // Add other theme customization as needed
  },
  typography: {
    // Customize typography settings if needed
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#fff",
          },
        },
      },
    },
  },
});

export default customTheme;
