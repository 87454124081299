import React from "react";
import { Tabs, Tab, AppBar } from "@mui/material";
import PDFPreviewTab from "./component/PreviewTab";
import ProjectsDocument from "./component/pdfs/Projects";
import AIProjectsDocument from "./component/pdfs/AILinks";
import StatementOfWorkDocument from "./component/pdfs/EvaluationSoW";

const exampleProjects = [
  {
    screenshot: "screenshots/project_uberai.png",
    logo: "assets/uber_logo_black.png",
    title: "REALTIME DEMAND FORECASTING",
    cost: "8.75M",
    compute: "1.5M",
    timeline: "16 months",
    technologies: [
      "assets/tensorflow_icon.png",
      "assets/pytorch_icon.png",
      "assets/kafka_icon.png",
    ],
    highlights: ["$100 million increase to revenue", "Deployed to 100+ cities"],
  },

  {
    screenshot: "screenshots/neurite_project_screenshot.png",
    title: "Pandemic Patient Severity Prediction & UI",
    logo: "assets/neurite_logo_black.png",
    cost: "1M",
    compute: "100k",
    timeline: "12 months",
    technologies: ["assets/pytorch_icon.png"],
    highlights: [
      "COVID Severity prediction model",
      "30M+ Patient records, 875 hospitals",
      "Mobile-first UI Dashboard",
    ],
  },
  {
    screenshot: "screenshots/greenscreendemo.png",
    logo: "assets/meta_logo_gradient.png",
    title: "Text-to-Image Green Screen",
    cost: "15M",
    compute: "5M",
    timeline: "12 months",
    technologies: ["assets/pytorch_icon.png", "assets/aws_logo.png"],
    highlights: [
      "Deployed to ~700m users",
      "Supported 5000+ QPS with ~10k GPUs",
    ],
  },
];

// Usage:
// <MyDocument projects={exampleProjects} />

function App() {
  const [tabValue, setTabValue] = React.useState(0);

  const pdfDocuments = [
    {
      label: "Projects",
      component: (
        <ProjectsDocument
          logo="assets/lextext_logo.png"
          projects={exampleProjects}
        />
      ),
      fileName: "projects.pdf",
    },
    {
      label: "AI Projects",
      component: <AIProjectsDocument />,
      fileName: "ai_projects.pdf",
    },
    {
      label: "Statement of Work",
      component: <StatementOfWorkDocument />,
      fileName: "sow.pdf",
    },
    // Add more entries here as you create more documents
  ];
  return (
    <div className="App">
      <AppBar position="static">
        <Tabs
          value={tabValue}
          onChange={(event, newValue) => setTabValue(newValue)}
        >
          {pdfDocuments.map((doc, index) => (
            <Tab key={index} label={doc.label} />
          ))}
        </Tabs>
      </AppBar>

      {pdfDocuments.map(
        (doc, index) =>
          tabValue === index && (
            <PDFPreviewTab
              key={index}
              documentComponent={doc.component}
              fileName={doc.fileName}
            />
          )
      )}
    </div>
  );
}

export default App;
